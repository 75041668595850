* {
  margin: 0;
  padding: 0;
}

body,
html {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#4d606d, #aacdf7);
  background-image: url("javascript/elements/resources/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Montserrat", sans-serif;
}
#overworld {
  position: absolute;
  z-index: 1;
}
#splash_screen {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #44464a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2365686c' fill-opacity='0.5'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c1c3c5;
}
.final {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #44464a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2365686c' fill-opacity='0.5'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c1c3c5;
}

#logo {
  height: 300px;
  width: 40%;
  background: url(javascript/elements/resources/images/logo_UI.svg) no-repeat
    center center;
  background-size: contain;
  margin-bottom: 20px;
  margin-top: 20px;
}
#splash_screen p {
  font-weight: 500;
}
#enemy {
  width: 50%;
}
#character {
  width: 50%;
}
#enemy_col {
  background-color: #95ad91;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 15%;
}
#info {
  margin-top: 70px;
  margin-bottom: 40px;
  font-style: italic;
}
.active {
  visibility: visible;
}
.inactive {
  visibility: hidden;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#battle {
  position: relative;
  height: min-content;
  width: 45%;
  z-index: 2;
  margin: auto;
  top: 20%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 30px 40px;
  background-color: #44464a;
  color: #c1c3c5;
}

#hp_text,
#enemy_hp_text {
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: right;
}
h1 {
  font-size: 2em_;
  padding: 15px 15px;
}
#hp_bar,
#enemy_hp_bar {
  background-color: #c1c3c5;
  padding: 8px;
  display: flex;
}

#hp_green,
#enemy_hp_green {
  /* -webkit-appearance: none;
  accent-color: #95ad91;
  padding: 10px;*/
  width: 100%;
}
progress {
  -webkit-appearance: none;
}
::-webkit-progress-bar {
  background-color: #c1c3c5;
}
::-webkit-progress-value {
  background-color: #95ad91;
}

#descriptor_en {
  font-style: italic;
  position: absolute;
  bottom: 50px;
  margin: auto;
}
#descriptor_ch {
  font-style: italic;
  position: absolute;
  bottom: 80px;
  margin: auto;
}

.action {
  padding: 8px 35px 8px 15px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  background-color: #65686c;
  font-size: 1.5em;
  cursor: pointer;
}
.circle_bg {
  width: 45px;
  height: 45px;
  background-color: #c1c3c5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_small {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.cyan {
  background-color: #91abad;
}
.magenta {
  background-color: #a791ad;
}
.yellow {
  background-color: #adaa91;
}
.key {
  background-color: #2d2d2d;
}
.defend {
  background-image: url(javascript/elements/resources/images/shield.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
